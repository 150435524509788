import React from 'react';
import classNames from 'classnames';

import './Heading.scss';

import { HeadingProps } from './Heading.types';

const Heading: React.FC<HeadingProps> = ({
  children,
  level = 1,
  stylingLevel,
  className,
  flatten
}) => {
  const tag = (level: 1 | 2 | 3 | 4 | 5 | 6) => {
    switch (level) {
      case 2:
        return 'h2';
      case 3:
        return 'h3';
      case 4:
        return 'h4';
      case 5:
        return 'h5';
      case 6:
        return 'h6';
      default:
        return 'h1';
    }
  };

  return React.createElement(
    tag(level),
    {
      className: classNames(
        {
          [`heading--h${stylingLevel}`]: stylingLevel,
          [`react-heading--flatten`]: flatten,
        },
        className,
      ),
    },
    children,
  );
};

export default Heading;
